export const ORIGINS = {
  AB: "Agriculture biologique",
  AOC: "Appellation d'origine contrôlée",
  AOP: "Appellation d'origine protégée",
  CHF: "Viande chèvre française",
  CVF: "Viande chevreau française",
  FGF: "Foie gras de France",
  FM: "Fait maison",
  IGP: "Indication géographique protégée",
  LAF: "Lapin de France",
  LOC: "Local",
  LR: "Label Rouge",
  MSC: "MSC",
  OEF: "Oeufs de France",
  PDU: "Pêche durable",
  POF: "Le porc français",
  RUP: "Région ultrapériphérique UE",
  VAF: "Viande d'agneau français",
  VBF: "Viande bovine française",
  VCF: "Viande chevaline française",
  VLF: "Volaille française",
  VOF: "Viande ovine française",
  VVF: "Viande de veau française"
};

export const PRODUCT_TYPES = {
  STARTER: "ENT",
  MAIN: "PLA",
  DESSERT: "DES",
  DRINK: "BOI",
  SIDE: "ACC",
  // FORMULA: "FOR",
  DAIRY: "LAI",
  CHEESE: "FRO",
  OTHER: "AUT",
  WRAP: "WRA",
  FOCACCIA: "FOC",
  SALAD: "SAL",
  BAGEL: "BAG",
  BREAD: "BRE"
};

export const PRODUCT_TYPES_NAMES = {
  [PRODUCT_TYPES.STARTER]: "Entrée",
  [PRODUCT_TYPES.MAIN]: "Plat",
  [PRODUCT_TYPES.DESSERT]: "Dessert",
  [PRODUCT_TYPES.CHEESE]: "Fromage",
  [PRODUCT_TYPES.DAIRY]: "Laitage",
  [PRODUCT_TYPES.DRINK]: "Boisson",
  [PRODUCT_TYPES.SIDE]: "Accompagnement",
  [PRODUCT_TYPES.OTHER]: "Autre",
  [PRODUCT_TYPES.WRAP]: "Wrap",
  [PRODUCT_TYPES.FOCACCIA]: "Focaccia",
  [PRODUCT_TYPES.SALAD]: "Salade",
  [PRODUCT_TYPES.BAGEL]: "Bagel",
  [PRODUCT_TYPES.BREAD]: "Baguette"
  // [PRODUCT_TYPES.FORMULA]: "Spécial : prix formule"
};
